/* Case Studies Card */
.case-card {
  border-radius: $rounded-medium;
  overflow: hidden;
  position: relative;
  background: #424242;
  @include shadow8;
  display: block;
  padding: 0 !important;
  margin-bottom: $spacing5;
  width: 500px;
  height: 180px;
  @include breakpoints-down(sm) {
    width: 280px
  }
  img {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
  &.portrait {
    width: 320px;
    height: 400px;
    @include breakpoints-down(sm) {
      width: 280px;
      height: 300px;
    }
    img {
      min-width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &.square {
    width: 500px;
    height: 400px;
    height: 400px;
    @include breakpoints-down(sm) {
      width: 280px;
    }
    img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }
  .figure {
    margin: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
    img {
      position: relative;
      object-fit: cover;
    }
  }
  .title {
    text-transform: capitalize;
    font-weight: font-bold;
    line-height: 36px;
    font-size: 24px;
    @include breakpoints-down(sm) {
      font-size: 18px;
    }
  }
  .logo {
    display: block;
    position: relative;
    img {
      z-index: 1;
      width: 100%
    }
  }
  .text {
    flex: 1
  }
  .desc {
    font-weight: $font-regular;
    font-size: 16px;
    white-space: normal;
    display: block;
    line-height: 1.5
  }

  .property {
    transition: all 0.3s ease-out;
    position: absolute;
    width: 100%;
    height: 150%;
    min-height: 170px;
    padding: $spacing1;
    bottom: 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    left: 0;
    color: $palette-common-white;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, $palette-secondary-dark 85%, $palette-primary-dark 120%);
    .title {
      white-space: normal;
      display: flex;
      font-weight: $font-bold;
      align-items: center;
    }
    .logo {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      overflow: hidden;
      @include palette-background-paper;
      text-align: center;
      @include margin-right($spacing2);
      img {
        display: block
      }
    }
    h6 {
      color: $palette-common-white;
      margin-bottom: $spacing2;
      line-height: 22px
    }
  }
}

