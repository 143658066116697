$liquid-deco: '../images/ai/liquid-deco.svg';

.page-deco {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  overflow: hidden;
  .top-deco, .bottom-deco {
    position: absolute;
    opacity: 0.1;
    background: url($liquid-deco) no-repeat transparent;
    background-size: 100%;
    width: 1122px;
    height: 950px;
  }
  .top-deco {
    top: -300px;
    right: -800px;
  }

  .bottom-deco {
    bottom: -800px;
    left: -120px;
  }
}
