$liquid: '../images/ai/parallax.png';
$stone: '../images/blockchain/parallax.png';

.footer-deco {
  .liquid {
    overflow: hidden;
    position: relative;
    @include breakpoints-up(md) {
      padding-bottom: $spacing10
    }
    &:after {
      content: "";
      width: 384px;
      height: 621px;
      background: url($liquid) transparent no-repeat;
      background-position: center center;
      background-size: 100%;
      position: absolute;
      @include right(160px);
      transform: rotate(270deg) scale(-1, 1);
      filter: blur(10px);
      opacity: 0.2;
      top: 0;
      @include breakpoints-down(md) {
        top: 200px
      }
    }
    &:before {
      content: "";
      width: 384px;
      height: 621px;
      background: url($liquid) transparent no-repeat;
      background-position: center center;
      background-size: 100%;
      position: absolute;
      @include left(-50px);
      transform: rotate(90deg) scale(-1, -1);
      filter: blur(10px);
      opacity: 0.2;
      top: 80px;
      @include breakpoints-down(md) {
        top: 100px;
      }
    }
  }

  .stone {
    padding-bottom: $spacing5;
    position: relative;
    overflow: hidden;
    &:after {
      content: "";
      width: 384px;
      height: 621px;
      background: url($stone) transparent no-repeat;
      background-position: center center;
      background-size: 100%;
      position: absolute;
      top: 180px;
      @include right(0);
      transform: rotate(160deg) scale(-1, -1);
      filter: blur(5px);
      opacity: 0.5;
      @include breakpoints-down(md) {
        top: 500px;
      }
    }
    &:before {
      content: "";
      width: 384px;
      height: 621px;
      background: url($stone) transparent no-repeat;
      background-position: center center;
      backgroundSize: 100%;
      position: absolute;
      top: 100px;
      @include left(190px);
      transform: rotate(-90deg) scale(-1, 1);
      filter: blur(5px);
      opacity: 0.5;
      @include breakpoints-down(md) {
        top: 400px;
      }
    }
  }

  .line {
    position: relative;
    padding: spacing(5, 0);
    .deco {
      top: -400px;
      @include breakpoints-up(sm) {
        top: -270px
      }
    }
  }

  .deco {
    overflow: hidden;
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 100%;
    @include breakpoints-up(xl) {
      display: none
    }
    svg {
      width: 1573px;
      height: 266px;
      position: absolute;
    }
    .primary {
      @include use-theme(stroke, $palette-primary-main, $palette-primary-light);
      top: 10px;
      @include left(0);
    }
    .secondary {
      stroke: $palette-secondary-main;
      top: 10px;
      @include left(-200px)
    }
  }

  .wave {
    position: relative;
    padding-top: 120px;
    padding-bottom: 40px;
    overflow: hidden;
    @include breakpoints-down(md) {
      padding-top: $spacing10
    }
    .deco {
      top: 20px;
      z-index: 2;
      opacity: 0.5
    }
    &:before {
      content: "";
      z-index: 0;
      width: 100%;
      height: 100%;
      bottom: 0px;
      @include left(0);
      opacity: 0.4;
      display: block;
      position: absolute;
      @include use-theme(background, $gradient-triple-light, $gradient-triple-dark);
    }
  }

  .deco-mask {
    position: absolute;
    height: 300px;
    top: 0px;
    @include left(0px);
    z-index: 2;
    width: 1280px;
    @include breakpoints-up(xl) {
      transform: scale(1.5);
      top: 10px;
    }
    svg {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      top: 0;
    }
    .main {
      @include use-theme(fill, $light-background-paper, $palette-primary-dark);
    }
    .darken {
      @include use-theme(fill, $light-background-paper, rgba($palette-common-black, 0.5));
    }
  }

  .primary-dark { background: $gradient-primary-dark }
  .primary-light { background: $gradient-primary-light }
  .secondary-dark { background: $gradient-secondary-dark }
  .secondary-light { background: $gradient-secondary-light }
  .accent { background: $gradient-accent }
  .double-light { background: $gradient-double-light }
  .double-main { background: $gradient-double-main }
  .double-dark { background: $gradient-double-dark }
  .triple-light { background: $gradient-triple-light }
  .triple-main { background: $gradient-triple-main }
  .triple-dark { background: $gradient-triple-dark }
  .fogs {
    position: relative;
    padding: spacing(10, 0, 5);
    @include breakpoints-down(md) {
      padding-top: $spacing10
    }
    .fog {
      position: absolute;
      filter: blur(50px);
      opacity: 0.5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include breakpoints-up(sm) {
        overflow: hidden
      }
    }
    .start {
      bottom: 0;
      position: absolute;
      overflow: hidden;
      width: 300px;
      @include left(-200px);
      height: 100%;
      > div {
        position: absolute;
      }
    }
    .end {
      bottom: 0;
      position: absolute;
      overflow: hidden;
      @include right(0);
      width: 300px;
      height: 100%;
      > div {
        position: absolute;
      }
    }
  }
}