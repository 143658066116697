.paper.card {
  border-radius: $rounded-big;
  header {
    display: flex;
    margin-bottom: $spacing1;
    padding: spacing(0, 2)
  }
  &.color {
    color: $palette-common-white;
    background: $palette-primary-main;
    color: $palette-common-white;
  }
  &.no-margin {
    margin: 0;
  }
  .icon-title {
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 44px;
    vertical-align: middle;
    @include margin-right($spacing2);
    &.gradient-icon {
      i {
        color: transparent;
        background: linear-gradient(to bottom, $palette-primary-light 0%, $palette-primary-main 85%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    i {
      font-size: 40px;
      vertical-align: baseline;
      color: $palette-primary-main;
    }
  }

  .title-paper {
    padding-top: 0;
    font-weight: $font-bold;
    font-size: 22px;
    line-height: 1.6;
    position: relative;
    text-transform: capitalize;
  }

  .text {
    & > * {
      padding-left: $spacing1;
      padding-right: 0;
    }
  }

  &.primary {
    @include use-theme(background-color, $palette-primary-main, $palette-primary-dark);
    .title-paper {
      color: material-color('grey', '100');
      &:after {
        border0bottom: 5px solid $palette-primary-light
      }
    }
    .description {
      color: material-color('grey', '100');
    }
    .icon-title {
      i {
        color: $palette-common-white
      }
    }
  }

  &.secondary {
    @include use-theme(background-color, $palette-secondary-main, $palette-secondary-dark);
    .title-paper {
      color: material-color('grey', '100');
      &:after {
        border0bottom: 5px solid $palette-primary-light
      }
    }
    .description {
      color: material-color('grey', '100');
    }
    .icon-title {
      i {
        color: $palette-common-white
      }
    }
  }

  &.gradient {
    padding: $spacing2;
    color: $palette-common-black;
    background: $gradient-triple-light;
    .title-paper {
      color: $palette-common-black;
      font-weight: $font-bold;
    }
    .icon-title {
      i {
        color: $palette-common-black
      }
    }
  }

  &.transparent {
    background: none;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    padding: 0;
  }

  .overflow-x {
    width: 100%;
    overflow-x: auto
  }
}

