#avatar-about {
  .use-text-grd-double-custom {
    .theme--light & {
      @extend .use-text-grd-double-light;
    }
    .theme--dark & {
      @extend .use-text-grd-double-main;
    }
  }

  .about {
    position: relative;
    display: block;
    padding-bottom: 50px;
    @include breakpoints-up(lg) {
      padding-bottom: 200px
    }
  }

  .thumb {
    border-radius: $rounded-big;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    @include breakpoints-down(lg) {
      width: 100%;
      height: 100px;
    }
  }

  .lower {
    @include breakpoints-up(sm) {
      margin-top: $spacing10
    }
    @include breakpoints-down(md) {
      @include margin-left($spacing5);
    }
  }

  .higher {
    @include breakpoints-up(sm) {
      margin-top: $spacing3 * -1;
    }
  }

  .name-deco {
    margin: 0;
    padding: 0;
    transform: rotate(-90deg);
    transform-origin: top left;
    position: absolute;
    letter-spacing: 5px;
    font-weight: $font-light;
    text-transform: uppercase;
    background: $gradient-double-main;
    -webkit-background-clip: text;
    -webkit-textFill-color: transparent;
    color: $palette-secondary-main;
    top: $spacing1 * 110;
    @include left($spacing1 * -3);
    font-size: 100px;
    opacity: 0.25;
    z-index: 20;
  }
}