.desktop-app-artworks {
  .root {
    perspective: 1000px;
    display: block;
    position: relative;
    top: -30px;
    @include breakpoints-up(sm) {
      top: -50px;
      left: 60px;
    }
  }

  .desktop {
    position: relative;
    transform: rotateY(42deg) rotateX(45deg) rotateZ(-20deg);
    border-radius: $rounded-big;
    background: linear-gradient(0deg, $palette-secondary-main -20%, $palette-secondary-light -10%, $palette-primary-dark 50%, $palette-primary-main 55%, $palette-accent-light 80%, $palette-secondary-main 120%);
    width: 600px;
    height: 388px;
    @include breakpoints-down(sm) {
      width: 300px;
      height: 194px
    }
  }

  .screen {
    border-radius: $rounded-big;
    overflow: hidden;
    position: relative;
    left: 8px;
    top: -4px;
    width: 590px;
    height: 390px;
    @include breakpoints-down(sm) {
      width: 295px;
      height: 195px
    }
    img {
      width: 100%
    }
  }

  .reflect {
    position: absolute;
    bottom: -110%;
    left: 30%;
    @include use-theme(opacity, 0.2, 0.8);
    @include use-rtl-ltr(display, none, block);
    transform: rotateY(40deg) rotateX(-15deg) rotateZ(0deg) scale(1.1, -1.1);
    &:before {
      content: "";
      position: absolute;
      width: 120%;
      height: 110%;
      bottom: 0px;
      left: -20px;
      z-index: 1;
      @include use-theme(background, linear-gradient(190deg, $light-background-paper 90%, rgba(0, 0, 0, 0) 120%), linear-gradient(190deg, $palette-primary-dark 90%, rgba(0, 0, 0, 0) 120%));
    }
    &:after {
      content: "";
      position: absolute;
      width: 120%;
      height: 110%;
      bottom: 0;
      left: -20px;
      z-index: 1;
      opacity: 0.5;
      @include use-theme(background, linear-gradient(260deg, $light-background-paper 90%, rgba(0, 0, 0, 0) 160%), linear-gradient(190deg, $palette-common-black 90%, rgba(0, 0, 0, 0) 160%));
    }
  }

  .widget {
    transform: rotateY(42deg) rotateX(45deg) rotateZ(-21deg);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @include breakpoints-down(sm) {
      display: none
    }
    span {
      z-index: 2;
      position: absolute;
      border-radius: $rounded-medium;
      overflow: hidden;
      box-shadow: 0 10px 20px 5px rgba(0, 0, 0, 0.25);
      img {
        width: 100%
      }
    }
  }

  .top {
    width: 225px;
    height: 120px;
    top: -30px;
    left: 50px;
  }

  .left {
    width: 130px;
    height: 90px;
    bottom: 30px;
    left: -30px;
  }

  .right {
    width: 220px;
    height: 100px;
    bottom: -20px;
    right: -40px;
  }
}