#counter-carousel {
  .root {
    position: relative;
    text-align: center;
    padding: spacing(10, 0);
    color: $palette-common-white;
    @include breakpoints-down(sm) {
      padding-bottom: 0
    }
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include breakpoints-down(md) {
      padding: 0;
      .v-container {
        #main-wrap & {
          padding: 0;
        }
      }
    }
    figure {
      width: 100%;
      border-radius: 50px;
      overflow: hidden;
      margin: 0;
      background: $palette-common-black;
      height: 640px;
      @include breakpoints-down(sm) {
        height: 400px;
      }
      img {
        opacity: 0.3;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover
      }
    }
  }

  .slider-wrap {
    position: relative;
  }

  .carousel {
    position: relative;
    margin: spacing(8, 0);
    @include breakpoints-down(md) {
      margin-top: $spacing3
    }
  }

  .item {
    padding: spacing(1, 3);
    img {
      width: 90px;
      display: block
    }
    &:focus {
      outline: none
    }
  }

}
  