.parallax-blur {
  .parallax-wrap {
    position: absolute;
    width: 100%;
    height: 99%;
    left: 0;
    z-index: 0;
    @include breakpoints-down(md) {
      display: none;
    }
  }

  .inner-parallax {
    height: 2500px;
    width: 50%;
    position: absolute;
    display: block;
    @include use-theme(opacity, 0.5, 0.75);
    .figure {
      height: 2500px;
      width: 50%;
      display: block;
      position: absolute;
      :deep(.Masthead), :deep(.Masthead__image) {
        overflow: visible
      }
      img {
        height: auto;
        margin: 0;
        display: block;
      }
    }
    .figure > div {
      height: 2500px;
      width: 50%;
      display: block;
      position: absolute;
      top: 0;
    }
  }

  .left {
    left: 10px;
    top: 600px;
    img {
      transform: rotate(70deg) scale(1);
      transform-origin: center center;
    }
  }

  .right {
    top: -200px;
    right: -600px;
    img {
      transform: rotate(-30deg) scale(1);
      transform-origin: center center;
    }
  }
  
  .right2 {
    top: 400px;
    right: 100px;
    img {
      transform: rotate(-30deg) scale(1);
      transform-origin: center center;
    }
  }

  .top {
    top: 250px;
    @include right(0);
    z-index: 1;
    opacity: 0.5;
  }

  .bottom {
    top: 800px;
    @include left(0);
    opacity: 0.5;
    z-index: 1;
    img {
      transform: rotate(30deg) scaleX(-1);
      transform-origin: center center
    }
  }

  .primary-dark { background: $gradient-primary-dark }
  .primary-light { background: $gradient-primary-light }
  .secondary-dark { background: $gradient-secondary-dark }
  .secondary-light { background: $gradient-secondary-light }
  .accent { background: $gradient-accent }
  .double-light { background: $gradient-double-light }
  .double-main { background: $gradient-double-main }
  .double-dark { background: $gradient-double-dark }
  .triple-light { background: $gradient-triple-light }
  .triple-main { background: $gradient-triple-main }
  .triple-dark { background: $gradient-triple-dark }

  .fog {
    filter: blur(60px);
    position: relative;
    > div {
      border-radius: 500px;
      position: absolute
    }
  }

  .fog-inner {
    position: relative;
    overflow: hidden;
    width: 700px;
    height: 600px;
    > div {
      border-radius: 500px;
      position: absolute
    }
  }
}