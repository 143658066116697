.parallax-title {
  text-align: center;
  h3 {
    overflow: hidden;
    width: 100%;
    @include breakpoints-up(sm) {
      padding: spacing(0, 3)
    }
  }
  .deco-title {
    position: absolute;
    width: 100%;
    font-weight: $font-bold;
    top: -100px;
    left: 0;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 200px;
    display: flex;
    justify-content: center;
    @include breakpoints-down(lg) {
      font-size: 160px;
      top: 0px;
    }
    @include breakpoints-down(md) {
      font-size: 120px;
    }
    @include breakpoints-down(sm) {
      top: 0;
      font-Size: 60px;
    }
    &.static {
      opacity: 0.4;
      top: -10px;
      @include breakpoints-down(sm) {
        top: 20px;
      }
    }
  }
}
