$gold: #D6BD96;

#avatar-profile {
  .use-mq {
    &--text-subtitle {
      @include use-text-subtitle;
      @include breakpoints-down(lg) {
        @include use-text-paragraph;
      }
    }
  }
  .root {
    position: relative;
    @include breakpoints-down(md) {
      text-align: center;
    }
    @inlcude breakpoints-up(lg) {
      max-width: 1280px;
      padding: 0;
    }
    > div {
      @include breakpoints-down(sm) {
        padding: 0
      }
    }
  }

  .about {
    display: flex;
    flex-direction: column-reverse;
    @include breakpoints-up(md) {
      flex-direction: row;
      justify-content: space-between
    }
    @include breakpoints-down(sm) {
      text-align: center;
      > div > h5 {
        font-weight: $font-regular;
        font-size: 18px;
        line-height: 28px;
        margin-top: $spacing7;
        padding: spacing(0, 2)
      }
    }
  }

  .reward {
    flex: 1;
    display: flex;
    justify-content: space-between;
    @include breakpoints-up(lg) {
      padding: spacing(5, 7, 0, 0);
    }
    @include breakpoints-down(md) {
      max-width: 600px;
      margin: 0 auto;
      width: 100%;
    }
    @include breakpoints-down(sm) {
      overflow: auto;
    }
  }

  .item {
    text-align: center;
    color: $gold;
    margin: $spacing2;
    @include breakpoints-up(md) {
      margin: $spacing4
    }
    @include breakpoints-down(sm) {
      padding-left: $spacing3;
      padding-right: $spacing3
    }
    figure {
      height: 70px;
      margin: spacing(0, 0, 2);
      display: inline-block;
      @include breakpoints-down(lg) {
        height: 50px;
      }
      img {
        height: 100%
      }
    }
    p {
      font-family: Times New Roman;
      margin-bottom: 0;
      @include breakpoints-down(lg) {
        font-size: 16px;
        white-space: nowrap;
      }
    }
    h5 {
      font-weight: $font-medium;
      text-transform: uppercase;
      @include breakpoints-down(sm) {
        font-size: 16px;
      }
    }
  }

  .photo {
    position: relative;
    z-index: 1;
    margin-top: -200px;
    @include breakpoints-up(md) {
      margin-top: -380px;
      @include margin-left(-60px);
    }
    @include breakpoints-down(md) {
      margin-left: auto;
      margin-right: auto;
    }
    @include breakpoints-down(sm) {
     margin-top: -240px; 
    }
    figure {
      margin: 0;
      border-radius: $rounded-big;
      overflow: hidden;
      img {
        width: 101%;
        min-height: 101%;
      }
    }
  }

  .avatar {
    img {
      max-height: 600px;
      position: relative;
      @include breakpoints-up(md) {
        top: 100px;
      }
    }
  }

  .socmed {
    display: flex;
    justify-content: center;
    margin: $spacing4 auto;
    max-width: 600px;
    button, a {
      margin: spacing(1, 2);
      width: 36px;
      height: 36px;
      i {
        @include use-theme(color, $palette-primary-dark, $palette-primary-light)
      }
    }
    i {
      font-size: 22px;
    }
  }

  .live-paper {
    position: absolute;
    border-radius: $rounded-big;
    padding: $spacing2;
    bottom: 0;
    @include text-align(left);
    margin-left: $spacing2;
    margin-right: $spacing2;
    @include breakpoints-between(md, lg) {
      @include margin-left($spacing8);
      padding: $spacing1;
    }
    figure {
      border-radius: $rounded-big;
      overflow: hidden;
      height: 120px;
      img {
        width: 100%;
        min-height: 100%;
      }
    }
    h6 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: $spacing2
    }
  }

  .live {
    font-size: 14px;
    @include palette-text-secondary;
    strong {
      @include palette-text-primary;
      text-transform: uppercase;
      @include margin-right($spacing1)
    }
    span {
      width: 16px;
      height: 16px;
      display: inline-block;
      border-radius: 50%;
      @include margin-right(2px);
      top: 3px;
      position: relative;
      background-image: linear-gradient(159deg, #FF4081 0%, #C00D4F 100%);
    }
  }
}