.countdown-card {
  position: relative;
  width: 100%;
  border-radius: $rounded-medium;
  @include text-align(left);
  figure {
    margin: 0px;
    overflow: hidden;
    justify-content: center;
    display: flex;
    position: relative;
    img {
      width: 100%;
      min-height: 100%;
      object-fit: cover
    }
  }
  .property {
    display: flex;
    justify-content: space-between;
    padding: 0;
    strong {
      font-weight: $font-medium;
      font-size: 18px;
      @include use-theme(color, $palette-secondary-dark, $palette-secondary-light);
    }
  }
  .hidden-link {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .desc {
    padding: spacing(2, 3);
    overflow: hidden
  }

  .text {
    padding-left: 0;
    padding-right: 0;
  }

  .title {
    font-size: 20px;
    height: 30px;
    font-weight: $font-bold
  }

  .button.v-btn {
    margin-top: $spacing2;
  }

  /* Orientation */
  &.portrait {
    max-width: 350px;
    figure {
      height: 250px;
    }
    .desc {
      padding: $spacing2;
    }
  }

  &.landscape {
    display: flex;
    @include breakpoints-up(sm) {
      height: 150px;
    }
    figure {
      width: 160px;
      height: 150px;
    }
    .desc {
      @include breakpoints-up(sm) {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
      }
      @include breakpoints-down(sm) {
        width: 65%;
      }
    }
    .button.v-btn {
      width: 200px;
      @include breakpoints-down(sm) {
        display: none
      }
    }
    .hidden-link {
      @include breakpoints-down(sm) {
        display: block;
      }
    }
  }

  /* Type */
  &.full {
    overflow: hidden;
    figure {
      border-radius: 0 !important;
    }
  }

  &.round {
    figure {
      overflow: hidden;
      border-radius: $rounded-medium;
      height: 134px;
    }
    &.portrait {
      figure {
        position: relative;
        top: $spacing1;
        margin-left: $spacing1;
        margin-right: $spacing1;
        margin-bottom: $spacing1;
      }
    }
    &.landscape {
      figure {
        margin-top: $spacing1;
        margin-bottom: $spacing1;
        @include margin-left($spacing1);
      }
    }
  }

  &.oval {
    overflow: hidden;
    &.portrait {
      figure {
        height: 150px;
        width: 120%;
        @include margin-left(-10%);
        max-height: none;
        overflow: hidden;
        border-radius: 0 0 50% 50%;
      }
    }
    &.landscape {
      @include breakpoints-down(sm) {
        height: 150px;
      }
      figure {
        overflow: hidden;
        border-radius: 0 50% 50% 0;
        @include use-rtl(border-radius, 50% 0 0 50%);
        height: 250px;
        margin-top: -50px;
      }
    }
  }

  &.over {
    overflow: visible;
    figure {
      overflow: hidden;
      @include shade;
      position: relative;
      border-radius: $rounded-medium;
    }
    &.portrait {
      margin-top: $spacing2;
      figure {
        margin: spacing(0, 2);
        top: $spacing2 * -1;
        margin-bottom: $spacing2 * -1;
      }
    }
    &.landscape {
      @include margin-left($spacing2);
      figure {
        margin: spacing(2, 0);
        height: 118px;
        width: 140px;
        @include left($spacing2 * -1);
        @include margin-right($spacing2 * -1)
      }
    }
  }


  .avatar {
    width: 24px;
    height: 24px;
    box-sizing: content-box;
    @include margin-left(-8px);
    border: 1px solid $palette-common-black;
  }

  .head {
    position: absolute;
    align-items: center;
    top: $spacing1 * -1;
    display: flex;
    justify-content: center;
    z-index: 1;
    width: 100%;
  }

  .like {
    margin: 10px 0;
    font-weight: $font-medium;
    i {
      @include margin-right($spacing1);
      font-size: 18px;
    }
    button {
      width: 24px;
      height: 24px;
    }
  }

  .price {
    background: $palette-common-black;
    color: $palette-common-white;
    margin: spacing(0, 1);
  }

  .countdown {
    border-radius: $rounded-medium;
    padding: 12px;
    font-size: 18px;
    display: block;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: saturate(180%) blur(20px);
    color: $palette-common-white;
    position: absolute;
    bottom: $spacing1
  }

  .time {
    display: block;
    text-align: center;
    font-size: 12px;
    > span {
      display: flex;
      justify-content: center;
      margin: 0
    }
    strong {
      font-weight: $font-bold;
      font-size: 18px;
      display: block
    }
    i {
      margin: spacing(2, 1, 0);
      font-style: normal
    }
  }
}
