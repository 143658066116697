.filter-style {
  .modal-filter #filter_mobile {
    @include breakpoints-down(md) {
      padding: $spacing4;
      height: 100%;
      overflow: auto;
      width: 100%;
    }
  }
  .strip {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacing2;
    line-height: 0px;
  }
  .filter-block {
    margin-bottom: $spacing3;
    .collection {
      border: none;
      li {
        border-radius: $rounded-big;
        overflow: hidden;
      }
      .secondary-content {
        @include palette-text-primary;
      }
      &-header {
        background: none;
        border: none;
        @include palette-text-primary;
        font-weight: $font-medium;
        align-items: flex-end;
        font-size: 18px;
        @include breakpoints-up(lg) {
          font-size: 24px;
        }
      }
      &-item.active {
        @include use-theme(background-color, $palette-primary-light, $palette-primary-dark);
        @include use-theme(color, $palette-primary-dark, $palette-primary-light);
      }
    }
    .label-block {
      display: block;
      cursor: pointer;
      @include palette-text-primary;
    }
    .detail {
      border: none;
    }
  }
  .collection-item {
    display: block;
    border: none;
    label {
      margin-bottom: 0
    }
  }
  .star {
    display: block
  }
  .star-icon {
    @include palette-text-secondary
  }
  .star-icon-disable {
    @include palette-text-secondary;
    opacity: 0.5;
  }
  .btn-tag {
    position: relative;
    cursor: pointer;
    label {
      display: inline-block;
      padding: 1px 6px;
      margin: 4px;
      border-radius: $rounded-big;
      border: 1px solid $palette-primary-main;
      cursor: pointer;
      position: relative;
      transition: all 0.3s ease;
      @include palette-background-default;
    }
    input {
      opacity: 0;
      position: absolute;
      &:checked + label {
        color: $palette-common-white;
        background: $palette-primary-main;
      }
    }
  }
  .input-price {
    width: 75px;
    display: inline-block;
  }
  .apply-range {
    @include rtl-flip
  }
  &.modal-content {
    padding: 0;
    border: none;
  }
  .root-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing5;
    h6 {
      @include breakpoints-up(md) {
        @include use-text-subtitle2
      }
      @include breakpoints-down(md) {
        @include use-text-paragraph
      }
    }
    @include breakpoints-down(lg) {
      h6 {
        flex: 1;
        white-space: nowrap;
        margin: spacing(0, 2)
      }
      > div {
        width: 100%
      }
    }
  }

  .total {
    flex: 1;
    @include text-align(right);
    @include breakpoints-down(sm) {
      width: 100%;
      flex-basis: 100%;
      padding: $spacing1
    }
  }
  .tab-filter {
    position: relative;
    z-index: 20;
    overflow: auto;
    width: auto;
    flex-grow: inherit;
    padding: $spacing1;
    display: flex;
    background: $gradient-triple-light;
    border-radius: 40px;
    @include breakpoints-down(md) {
      padding: spacing(1, 2);
    }
    > .btn {
      background: transparent;
      border-radius: 40px;
      font-weight: $font-medium;
      color: $palette-common-black;
      white-space: nowrap;
      min-width: 100px;
      display: inline-flex;
      -webkit-box-flex: 0;
      flex: 0 0 auto;
      font-size: 14px;
      align-items: center;
      justify-content: center;
      &:not(.selected):hover {
        color: $palette-common-black !important;
      }
      @include breakpoints-up(lg) {
        font-size: 16px;
        padding: spacing(0, 2);
        margin: spacing(0, 2);
        box-shadow: none;
      }
      &.selected {
        background: $palette-common-black;
        color: $palette-common-white
      }
    }
  }
}

.modal-filter {
  position: relative;
  overflow: hidden;
  height: 100%;
  border: none;
  &.modal-content {
    padding-top: $spacing8
  }
}

nav.filter-head {
  background: $palette-primary-main;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  .nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $palette-common-white;
    button {
      color: $palette-common-white;
      margin: $spacing1
    }
  }
}

.sorter {
  @include breakpoints-down(sm) {
    flex-direction: column-reverse;
    > div {
      flex-wrap: wrap;
    }
    .btn {
      white-space: nowrap;
    }
  }
  .btn-select, .btn-filter {
    @include breakpoints-down(sm) {
      width: 49%;
    }
  }
  .total-result {
    @include margin-left($spacing3);
    @include text-align(right);
    @include breakpoints-up(sm) {
      flex: 1;
    }
    @include breakpoints-down(sm) {
      width: 100%
    }
  }
  .input-field {
    border-radius: $rounded-small;
    border: 1px solid;
    flex: 1;
    padding: spacing(0.5, 0);
    margin-left: $spacing1;
    margin-right: $spacing1;
    @include palette-background-paper;
    @include use-theme(border-color, $dark-text-secondary, $light-text-secondary);
    @include breakpoints-down(md) {
      @include margin-left(0)
    }
  }
  .toggle-btn-group {
    display: flex;
    .btn {
      border-radius: 0;
      @include palette-background-paper;
      i {
        font-size: 24px;
        line-height: 30px;
      }
      &.active {
        @include use-theme(background-color, $palette-primary-light !important, $palette-primary-main !important);
        color: $palette-primary-light !important;
      }
    }
    > .btn:not(:last-child) {
      border-right: none;
    }
    > .btn:first-child {
      border-top-left-radius: $rounded-small;
      border-bottom-left-radius: $rounded-small;
      @include rtl-flip
    }
    > .btn:last-child {
      border-top-right-radius: $rounded-small;
      border-bottom-right-radius: $rounded-small;
      @include rtl-flip
    }
  }
  .input-field .select-wrapper input {
    height: 41px;
    line-height: normal;
    padding: 10px 12px;
  }
}

.search-banner {
  .container {
    padding: 0 !important;
    z-index: 2;
  }
  .search {
    position: relative;
    display: flex;
    align-items: center;
    fieldset {
      height: 41px;
    }
    .input-field {
      @include padding-left($spacing2);
      border-radius: $rounded-small;
      border: 1px solid;
      @include palette-background-paper;
      @include use-theme(border-color, $dark-text-secondary, $light-text-secondary);
      min-height: 0;
      margin-bottom: 0;
      width: 100%;
      .prefix {
        @include left($spacing1)
      }
      input {
        padding: $spacing1;
        margin-left: $spacing3;
        max-height: none;
        border: none !important;
        box-shadow: none !important;
        margin-bottom: 0;
      }
    }
  }
}
