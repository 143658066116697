/* Testimonial Card */
.testi-rating-card {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 10;
    left: 0;
    border-radius: 50px;
    border: 1px solid $palette-primary-main;
    width: 240px;
    height: 240px;
    transform: rotate(-5deg);
    transform-origin: bottom left;
  }
  .paper {
    @include use-theme(background, rgba($light-background-paper, 0.5), rgba($dark-background-paper, 0.5));
    backdrop-filter: saturate(180%) blur(20px);
    border: 1px solid $palette-secondary-main;
    padding: $spacing3;
    width: 240px;
    height: 240px;
    border-radius: 50px;
    position: relative;
    p {
      height: 130px;
      overflow: hidden;
    }
  }

  .rating {
    margin-top: $spacing4
  }

  .star-icon {
    color: #FFC107
  }

  .star-icon-disable {
    .theme--light & {
      color: $light-divider;
    }
    .theme--dark & {
      color: $dark-divider;
    }
  }

  .avatar-profile {
    padding: 4px;
    background: $gradient-triple-light;
    img {
      border-radius: 50%;
      width: 100%;
    }
  }

  .person {
    display: flex;
    margin-top: $spacing3;
    .avatar {
      width: 55px;
      height: 55px;
    }
    .caption {
      font-style: italic;
      font-weight: 300
    }
  }

  .name {
    @include margin-left($spacing2)
  }
}

