#avatar-statistic {
  .root-categories {
    position: relative;
    z-index: 2;
  }
  .category {
    display: flex;
    align-items: center;
    margin-top: $spacing5;
    @include margin-left($spacing5);
  }

  .bg {
    border-radius: $rounded-medium;
    position: relative;
    overflow: visible;
    height: 100px;
    width: 100px;
    @include palette-background-paper;
    background-size: 150%;
    @include margin-right($spacing2);
    > div {
      font-size: 100px;
      position: relative;
      bottom: 50px;
      @include left(-10px);
    }
    &.red {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('red', '500') 100%);
    }
    &.pink {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('pink', '500') 100%);
    }
    &.purple {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('purple', '500') 100%);
    }
    &.deep-purple {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('deep-purple', '500') 100%);
    }
    &.indigo {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('indigo', '500') 100%);
    }
    &.blue {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('blue', '500') 100%);
    }
    &.light-blue {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('light-blue', '500') 100%);
    }
    &.cyan {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('cyan', '500') 100%);
    }
    &.teal {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('teal', '500') 100%);
    }
    &.green {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('green', '500') 100%);
    }
    &.light-green {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('light-green', '500') 100%);
    }
    &.lime {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('lime', '500') 100%);
    }
    &.yellow {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('yellow', '500') 100%);
    }
    &.amber {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('amber', '500') 100%);
    }
    &.orange {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('orange', '500') 100%);
    }
    &.deep-orange {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('deep-orange', '500') 100%);
    }
    &.brown {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('brown', '500') 100%);
    }
    &.grey {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('grey', '500') 100%);
    }
    &.blue-grey {
      background-image: linear-gradient(110deg, rgba(255, 255, 255, 1) 0%, material-color('blue-grey', '500') 100%);
    }
  }
  .text-categories {
    p {
      font-weight: $font-medium;
      margin-bottom: 0;
    }
  }

  .name {
    text-transform: uppercase;
    font-size: 36px;
    display: block;
  }

  .title {
    text-align: center;
    text-transform: uppercase
  }
}