.liquid-parallax {
  .parallax-wrap {
    position: absolute;
    width: 100%;
    height: 99%;
    left: 0;
    z-index: 0;
    @include breakpoints-down(md) {
      display: none;
    }
  }

  .inner-parallax {
    height: 2500px;
    width: 50%;
    position: absolute;
    display: block;
    filter: blur(20px);
    @include use-theme(opacity, 0.25, 0.5);
    .parallax-figure {
      height: 2500px;
      width: 50%;
      display: block;
      position: absolute;
      img {
        height: auto;
        margin: 0;
        width: auto;
        display: block;
      }
    }
    .parallax-figure > div {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .left {
    top: 300px;
    left: -100px;
    img {
      transform: rotate(70deg) scale(1.5);
      transform-origin: center center;
    }
  }

  .right {
    top: -200px;
    right: -500px;
    img {
      transform: rotate(30deg) scale(-1.5);
      transform-origin: center center;
    }
  }

  .top {
    top: 500px;
    right: -400px;
    img {
      transform: rotate(-280deg) scale(1.5);
      transform-origin: center center;
    }
  }

  .bottom {
    top: 1000px;
    left: -150px;
    img {
      transform: rotate(100deg) scale(1.5);
      transform-origin: center center;
    }
  }
}