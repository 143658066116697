@media only screen and (min-width: 600px) {
  .m-application .text-sm-left {
    text-align: left !important;
  }

  .m-application .text-sm-right {
    text-align: right !important;
  }

  .m-application .text-sm-center {
    text-align: center !important;
  }

  .m-application .text-sm-justify {
    text-align: justify !important;
  }

  .m-application .text-sm-start {
    text-align: start !important;
  }

  .m-application .text-sm-end {
    text-align: end !important;
  }
}

@media (min-width: 960px) {
  .m-application .text-md-left {
    text-align: left !important;
  }

  .m-application .text-md-right {
    text-align: right !important;
  }

  .m-application .text-md-center {
    text-align: center !important;
  }

  .m-application .text-md-justify {
    text-align: justify !important;
  }

  .m-application .text-md-start {
    text-align: start !important;
  }

  .m-application .text-md-end {
    text-align: end !important;
  }
}

@media (min-width: 1264px) {
  .m-application .text-lg-left {
    text-align: left !important;
  }

  .m-application .text-lg-right {
    text-align: right !important;
  }

  .m-application .text-lg-center {
    text-align: center !important;
  }

  .m-application .text-lg-justify {
    text-align: justify !important;
  }

  .m-application .text-lg-start {
    text-align: start !important;
  }

  .m-application .text-lg-end {
    text-align: end !important;
  }
}

@media (min-width: 1904px) {
  .m-application .text-xl-left {
    text-align: left !important;
  }

  .m-application .text-xl-right {
    text-align: right !important;
  }

  .m-application .text-xl-center {
    text-align: center !important;
  }

  .m-application .text-xl-justify {
    text-align: justify !important;
  }

  .m-application .text-xl-start {
    text-align: start !important;
  }

  .m-application .text-xl-end {
    text-align: end !important;
  }
}

