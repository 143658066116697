.footer-sitemap {
  position: relative;
  z-index: 11;
  .container {
    position: relative;
    z-index: 10
  }
  &:after {
    content: "";
    width: 100%;
    height: 240px;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.15;
  }
  &.has-bg {
    padding-top: 600px;
    margin-top: -600px;
  }
  .title {
    text-transform: capitalize;
    font-weight: $font-medium;
    @include use-theme(color, $palette-primary-dark, $palette-primary-light);
  }
  .link {
    margin: spacing(1, 1.5);
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      line-height: 32px;
      a {
        @include palette-text-secondary;
        text-decoration: none;
      }
    }
  }
  .logo-area {
    @include breakpoints-down(md) {
      text-align: center;
    }
  }
  .body-2 {
    margin: spacing(2, 0)
  }
  .collapsible {
    border: none;
    box-shadow: none;
  }
  .collapsible-header {
    background: none;
    box-shadow: none;
    border: none;
  }
  .logo {
    text-align: center;
    display: flex;
    align-items: center;
    @include breakpoints-down(md) {
      justify-content: center;
    }
    img {
      width: 54px;
      height: 54px;
      @include margin-right($spacing2)
    }
  }
  .socmed {
    display: flex;
    justify-content: center;
    .btn {
      margin: $spacing1;
      @include palette-background-paper;
    }
    .icon {
      width: 24px;
      height: 24px;
      font-size: 24px;
      @include use-theme(color, $palette-primary-main, $palette-primary-light);
    }
  }
  .select-lang {
    margin: 0 auto;
    width: 200px;
    display: inherit;
    margin-top: $spacing2;
    svg.caret {
      z-index: 1;
      @include use-theme(fill, rgba(0, 0, 0, 0.04), rgba(255, 255, 255, 0.4));
    }
    input {
      @include palette-background-paper;
      box-shadow: 0 1.5px 12px 2px rgba(0, 0, 0, 0.06);
      .theme--dark & {
        color: $palette-primary-light;
        border-color: rgba(255, 255, 255, 0.2);
      }
      .theme--light & {
        color: $palette-primary-dark;
        border-color: rgba(0, 0, 0, 0.15);
      }
    }
  }
  .site-map-item {
    @include breakpoints-down('md') {
      padding-bottom: 0 !important;
      padding-top: 0 !important
    }
  }
  .accordion-root {
    background: none;
    box-shadow: none
  }
  #main-wrap .accordion-content.v-expansion-panel {
    background-color: transparent;
    &:before, &:after {
      display: none
    }
  }
  .accordion-icon {
    padding: 0
  }
  .collapsible-body {
    border-bottom: none;
    margin-bottom: $spacing2
  }
}