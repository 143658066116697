#fintech-subscribe-form {
  .subscribe-form{
    .root {
      position: relative;
      text-align: center;
      z-index: 10;
      .container {
        position: relative;
      }
    }

    .form {
      position: relative;
      margin-top: $spacing2
    }

    .field {
      padding-right: 150px;
      padding-left: $spacing3;
      color: #fff;
      width: 100%;
      border-radius: 56px;
      @include palette-background-paper;
      border: 1px solid $palette-primary-main;
      .input-field {
        margin: 0;
      }
      input {
        border: none;
        margin-bottom: 0;
        height: auto;
        padding: 15px 0;
      }
    }

    .button {
      position: absolute;
      @include right($spacing1);
      top: 7px;
      width: 140px;
      @include breakpoints-down(md) {
        padding: spacing(0.5, 3);
        min-width: 0;
        width:auto;
      }
      @include breakpoints-down(sm) {
        padding: spacing(0.5, 1)
      }
    }
  }
}
