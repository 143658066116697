.footer-basic {
  text-align: center;
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: 240px;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.15;
  }
  &.has-bg {
    padding-top: 600px;
    margin-top: -600px;
  }
  .container {
    position: relative;
  }
  .root {
    text-align: center;
    position: relative;
    z-index: 11;
    nav {
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      ul {
        margin: 0;
        padding: 0;
        li {
          display: inline-block;
          margin: $spacing1;
          float: none;
          a {
            @include palette-text-primary;
            text-transform: capitalize;
            text-decoration: none !important;
            padding: spacing(0, 1);
            font-size: 18px;
            @include breakpoints-down(sm) {
              font-size: 16px
            }
          }
        }
      }
    }
  }
  .logo {
    margin-bottom: $spacing3;
    img {
      width: 70px
    }
  }
  .subscribe {
    max-width: 560px;
    margin: 0 auto;
    margin-bottom: $spacing2;
    position: relative;
    h5 {
      font-weight: $font-bold
    }
  }
  .form {
    display: block;
    position: relative;
    margin-top: $spacing3
  }
  .field {
    width: 100%;
    padding: 0;
    margin: 0;
    input {
      @include padding-right($spacing20);
      @include breakpoints-down(sm) {
        @include padding-right(90px)
      }
    }
  }
  .button {
    position: absolute;
    @include right(0);
    top: $spacing1;
    width: 90px
  }
  .socmed {
    display: flex;
    width: 200px;
    margin: $spacing5 auto;
    justify-content: space-between;
    a.btn-icon {
      border-radius: 50%;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @include use-theme(background, $palette-primary-light !important,  $palette-primary-dark !important);
      .icon {
        @include use-theme(color, $palette-primary-dark !important,  $palette-primary-light !important);
      }
    }
    .icon {
      font-size: 24px
    }
  }
  .copyright {
    display: block;
    padding: spacing(1.5);
    p {
      margin-bottom: 0
    }
  }
}