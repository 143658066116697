.category-img-card {
  border-radius: $rounded-big;
  overflow: hidden;
  position: relative;
  @include palette-background-paper;
  @include shade;
  display: block;
  text-align: center;
  @include palette-text-primary;
  text-decoration: none;
  margin-bottom: $spacing2;
  &:hover {
    @include palette-text-primary;
    .images {
      background-position: 70%;
      .figure {
        &:first-of-type {
          transform: rotate(-3deg)
        }
        &:last-child {
          transform: rotate(3deg)
        }
        &.center {
          margin-bottom: $spacing1 * -1;
        }
      }
    }
  }
  .images {
    display: flex;
    align-items: flex-end;
    height: 200px;
    overflow: hidden;
    justify-content: space-between;
    border-radius: $rounded-big;
    padding: spacing(0, 3);
    transition: all 0.3s ease-out;
    background-size: 150%;
    &.red-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('red', '500') 100%)
    }
    &.pink-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('pink', '500') 100%)
    }
    &.purple-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('purple', '500') 100%)
    }
    &.deep-purple-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('deep-purple', '500') 100%)
    }
    &.indigo-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('indigo', '500') 100%)
    }
    &.blue-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('blue', '500') 100%)
    }
    &.light-blue-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('light-blue', '500') 100%)
    }
    &.cyan-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('cyan', '500') 100%)
    }
    &.teal-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('teal', '500') 100%)
    }
    &.green-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('green', '500') 100%)
    }
    &.light-green-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('light-green', '500') 100%)
    }
    &.lime-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('lime', '500') 100%)
    }
    &.yellow-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('yellow', '500') 100%)
    }
    &.amber-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('amber', '500') 100%)
    }
    &.orange-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('orange', '500') 100%)
    }
    &.deep-orange-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('deep-orange', '500') 100%)
    }
    &.brown-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('brown', '500') 100%)
    }
    &.grey-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('grey', '500') 100%)
    }
    &.blue-grey-bg {
      background-image: linear-gradient(110deg, rgba(0, 0, 0, 0) 0%, material-color('blue-grey', '500') 100%)
    }
  }
  .figure {
    margin-top: 0px;
    @include margin-right(0px);
    height: 179px;
    width: 50%;
    transition: all 0.3s ease-out;
    @include margin-left(-50%);
    display: block;
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin-bottom: $spacing5 * -1;
    border-radius: $rounded-medium;
    & img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &:first-of-type {
      @include margin-left(0);
      transform-origin: bottom left;
    }
    &:last-child {
      transform-origin: bottom right;
    }
    &.center {
      margin-bottom: $spacing3 * -1;
      z-index: 2
    }
  }

  .title-card {
    display: block;
    padding: spacing(2, 1);
    font-weight: $font-bold
  }
}

