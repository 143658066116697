#wallet-call-action {
  .action {
    position: relative;
    z-index: 1;
    border-radius: 60px;
    color: $palette-common-black;
    background: linear-gradient(to bottom, $palette-secondary-light 10%, $palette-accent-light 100%);
    @include breakpoints-between(sm, lg) {
      margin-top: $spacing20;
      margin-bottom: $spacing20
    }
    @include breakpoints-up(md) {
      margin-left: $spacing10;
      margin-right: $spacing10;
    }
    @include breakpoints-down(sm) {
      text-align: center;
      padding-left: $spacing2;
      padding-right: $spacing2;
    }
    &:before {
      content: "";
      height: 160px;
      position: absolute;
      z-index: -1;
      width: calc(100% - 10px);
      @include left(5px);
      top: -125px;
      clip-path: polygon(13% 0%, 0% 100%, 100% 100%, 87% 0);
      background: linear-gradient(transparent 35%, $palette-secondary-light 80%);
      @include breakpoints-down(sm) {
        display: none
      }
    }
  }

  .wrap {
    border-radius: 60px;
    padding: spacing(5, 2, 2);
  }

  .btn-area {
    margin: spacing(5, 0);
    position: relative;
    display: flex;
    @include breakpoints-down(md) {
      margin: spacing(2, 0),
    }
    @include breakpoints-down(sm) {
      justify-content: center
    }
    a {
      min-width: 150px;
      @include breakpoints-up(sm) {
        @include margin-right($spacing2);
        img {
          width: 160px;
        }
      }
      @include breakpoints-down(sm) {
        margin: 4px;
        min-width: 0;
        max-width: 150px;
        img {
          width: 100%
        }
      }
    }
  }

  .phone {
    border-radius: $rounded-big;
    background: $gradient-triple-main;
    padding: 3px;
    position: absolute;
    transform: rotate(-5deg);
    transform-origin: center;
    overflow: hidden;
    width: 200px;
    height: 376px;
    top: -40px;
    @include breakpoints-down(md) {
      @include left(0)
    }
    > div {
      border-radius: $rounded-big;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      overflow: hidden;
      @include bg-color-darken($palette-primary-dark, 0.5);
      span {
        position: relative;
        z-index: 1;
      }
    }
  }
}