#cv-pricing {
  .use-text-grd {
    &-triple-custom {
      .theme--light & {
        @extend .use-text-grd-triple-light;
      }
      .theme--dark & {
        @extend .use-text-grd-triple-main;
      }
    }
  }
  .root .container {
    padding-top: $spacing5;
  }
  .feature-list {
    list-style: none;
    padding: 0;
    @include margin-left(0);
    margin-top: $spacing5;
    @include breakpoints-down(md) {
      text-align: center
    }
    li {
      width: 49%;
      display: inline-block;
      margin-bottom: $spacing5;
      @include breakpoints-down(md) {
        padding: spacing(0, 1)
      }
      > div {
        display: block;
        width: 65px;
        height: 65px;
        border-radius: $rounded-medium;
        margin-bottom: $spacing2;
        @include breakpoints-down(md) {
          margin-left: auto;
          margin-right: auto
        }
        > div {
          font-size: 72px;
          top: -32px;
          position: relative;
        }
        &.primary {
          background: $palette-primary-light;
        }
        &.secondary {
          background: $palette-secondary-light;
        }
        &.accent {
          background: $palette-accent-light;
        }
      }
    }
  }

  .item {
    > div {
      @include breakpoints-down(sm) {
        margin-left: auto;
        margin-right: auto
      }
    }
  }

}