#about-team {
  .root {
    position: relative;
    z-index: 5;
  }

  .scroll-bg {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    li {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      opacity: 0;
      filter: blur(5px);
      transition: all 0.5s ease-out;
      &.current {
        opacity: 0.4
      }
    }
  }

  .background {
    position: relative;
    overflow: hidden;
    border-radius: 60px 60px 0 0;
    @include breakpoints-down(sm) {
      border-radius: 40px 40px 0 0;
    }
    &.images {
      padding-top: $spacing15;
      padding-bottom: $spacing20;
      @include breakpoints-down(sm) {
        padding-top: $spacing10;
      }
      &:before {
        content: "";
        height: 160px;
        width: 100%;
        position: absolute;
        bottom: -80px;
        left: 0;
        opacity: 0.5;
        @include use-theme(background, $light-background-paper, $palette-primary-dark);
        border-radius: 60px 60px 0 0;
        z-index: 1;
        @include breakpoints-down(sm) {
          border-radius: 40px 40px 0 0;
        }
      }
      &:after {
        content: "";
        height: 160px;
        width: 100%;
        position: absolute;
        bottom: -80px;
        left: 0;
        @include use-theme(background, $palette-common-white, $palette-common-black);
        border-radius: 60px 60px 0 0;
        @include breakpoints-down(sm) {
          border-radius: 40px 40px 0 0;
        }
      }
    }
    &.parallax {
      padding-top: $spacing5;
    }
  }

  .container {
    position: relative;
    z-index: 1;
  }

  .bg-gradient-scroll {
    @include use-theme(background, $gradient-triple-light, $gradient-double-main);
    @include use-theme(opacity, 0.75, 0.4);
    position: absolute;
    overflow: hidden;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .wrap {
    position: relative;
    z-index: 2;
  }

  .section {
    margin-top: $spacing5
  }

  // Parallax Background
  .parallax-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    @include breakpoints-down(lg) {
      display: none
    }
  }

  .inner-parallax {
    height: 3000px;
    position: absolute;
    display: block;
  }

  .full {
    top: 700px;
    width: 100%;
    right: 0;
  }

  .oval {
    opacity: 0.3;
    position: relative;
    display: block;
    margin-left: auto;
    mask-image: url(../images/decoration/oval.svg);
    mask-repeat: no-repeat;
    mask-size: 100%;
    -webkit-mask-image: url(../images/decoration/oval.svg);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 100%;
    span {
      background: $gradient-primary-dark
    }
    &.big {
      width: 350px;
      height: 350px;
      @include right(-1000px);
      top: 200px;
    }
  }

  .gradient {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .dots {
    position: relative;
    @include left(-400px);
    top: -100px;
    height: 500px;
  }

  .parallax-vertical {
    width: 100%;
    height: 100%;
    @include breakpoints-up(lg) {
      transform: scale(0.5);
      transform-origin: top left;
    }
    @include breakpoints-up(xl) {
      display: none
    }
  }

  .parallax-dot {
    top: 0;
    @include use-theme(fill, $light-text-disabled, $dark-text-disabled);
    width: 845px;
    height: 800px;
    opacity: 0.3;
    @include left(0)
  }

}