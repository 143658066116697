#avatar-testimonial {
  .root {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: spacing(10, 0);
    @include breakpoints-down(md) {
      padding: spacing(5, 0)
    }
  }

  .view-all {
    @include breakpoints-up(lg) {
      @include margin-right(160px);
    }
    @include breakpoints-up(sm) {
      font-size: 24px;
    }
    @include breakpoints-down(md) {
      margin-bottom: 32px;
    }
  }

  .icon {
    @include margin-left($spacing1);
    @include rtl-flip;
    svg {
      width: 36px;
    }
  }

  .carousel {
    overflow: hidden;
  }

  .carousel-handle {
    height: 380px;
    position: relative;
    z-index: 10;
  }

  .carousel-wrap {
    position: absolute;
    z-index: 2;
    width: 100%;
    overflow: hidden;
  }

  .carousel {
    position: relative;
    z-index: 3;
    .slick-dots {
      bottom: -32px;
      @include pagination-slide;
    }
  }

  .item {
    padding: $spacing2;
    &:focus {
      outline: none
    }
  }

  .item-prop-last {
    @include use-rtl-ltr(width, 350px, 400px);
  }

  .floating-artwork {
    position: absolute;
    width: 100%;
    left: 0;
    top: $spacing5;
    @include breakpoints-up(lg) {
      top: $spacing10 * -1;
    }
    @media (min-width: 1400px) {
      @include left($spacing10);
    }
  }

  .artwork {
    display: flex;
    align-items: flex-end;
    height: 540px;
    @include breakpoints-up(md) {
      @include margin-right($spacing5);
    }
    @include breakpoints-up(lg) {
      @include use-rtl-ltr(float, left, right);
    }
    @media (min-width: 1400px) {
      @include margin-right($spacing5 * -1)
    }
  }

  .avatar {
    padding: 6px;
    background: $gradient-triple-light;
    width: 120px;
    height: 120px;
    display: flex;
    img {
      background: $palette-common-white;
      border-radius: 50%;
      width: 100%;
    }
  }

  .avatar-art {
    position: relative;
    width: 450px;
    height: 450px;
    > div {
      position: absolute
    }
  }
}