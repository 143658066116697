#blockchain-about {
  .order-lg-5 {
    position: relative;
    z-index: 1;
  }
  .video-popup {
    max-width: none;
    @include breakpoints-up(sm) {
      width: 690px;
    }
    iframe {
      width: 100%
    }
  }

  .headline {
    margin-bottom: $spacing2;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.0125em;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    h4 {
      font-size: 20px;
      font-weight: $font-bold;
    }
  }

  .main-feature {
    position: relative;
    display: block;
  }

  .video {
    overflow: hidden;
    position: relative;
    margin: spacing(6, 0, 2);
    border-radius: $rounded-medium;
    height: 360px;
    @include breakpoints-down(lg) {
      max-width: 400px;
      height: 250px;
    }
    @include breakpoints-down(md) {
      margin-left: auto;
      margin-right: auto;
    }
    figure {
      margin: 0;
      background: $palette-common-black;
      img {
        opacity: 0.62;
        min-height: 100%;
        width: 100%;
        display: block;
      }
    }
  }

  .feature-wrap {
    position: relative;
    padding: spacing(0, 3);
  }

  .play-btn {
    position: absolute;
    width: 90px;
    height: 90px;
    top: 50%;
    @include palette-background-paper;
    @include shade;
    @include left(50%);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid $palette-common-white;
    i {
      display: block;
      line-height: normal;
      @include padding-left(10px);
      &:before {
        font-size: 60px;
        color: $palette-common-white;
        font-weight: bold;
      }
    }
  }

  .close-btn {
    position: absolute;
    top: 4px;
    @include right(4px);
  }

  .counter {
    margin-top: $spacing10;
    @include breakpoints-up(lg) {
      justify-content: flex-end
    }
    @include breakpoints-up(sm) {
      display: flex
    }
    @include breakpoints-up(md) {
      margin-top: $spacing5;
      @include padding-right($spacing8)
    }
    > div {
      @include breakpoints-down(md) {
        display: flex;
        justify-content: center;
        margin-bottom: $spacing10
      }
      @include breakpoints-down(sm) {
        margin-bottom: $spacing5
      }
      @include breakpoints-between(sm, md) {
        width: 50%
      }
    }
  }

  .lower {
    @include breakpoints-up(md) {
      margin-top: $spacing15
    }
  }

  .higher {
    @include breakpoints-up(md) {
      margin-top: $spacing3 * -1
    }
  }

  .paper {
    position: relative;
    height: 220px;
    overflow: visible;
    margin: spacing(1, 0);
    @include use-theme(background, rgba($light-background-paper, 0.8), rgba($dark-background-paper, 0.8));
    backdrop-filter: saturate(180%) blur(20px);
    border: none;
    width: 100%;
    border-radius: $rounded-medium;
    @include breakpoints-up(md) {
      margin: spacing(10, 4, 10, 0);
      width: 174px;
    }
    @include breakpoints-down(md) {
      margin: $spacing1;
      height: auto
    }
    @include breakpoints-down(sm) {
      margin: 4px;
      flex: 1
    }
    .icon {
      font-size: 140px;
      position: absolute;
      width: 100%;
      text-align: center;
      z-index: 2;
      top: -100px;
      @include breakpoints-down(md) {
        top: -60px;
        font-size: 100px;
      }
    }
    h4 {
      font-weight: $font-medium;
      @include breakpoints-down(md) {
        font-size: 22px
      }
    }
    p {
      font-size: 18px;
      @include breakpoints-down(md) {
        font-size: 14px;
      }
    }
  }
  .glow {
    overflow: hidden;
    position: relative;
    border-radius: $rounded-medium;
    padding: spacing(12, 3, 1);
    &:before {
      content: "";
      filter: blur(20px);
      opacity: 0.25;
      width: 100%;
      height: 190px;
      border-radius: 50%;
      position: absolute;
      top: -50%;
      left: 0;
    }
    &.primary {
      &:before {
        background: $palette-primary-main
      }
    }
    &.secondary {
      &:before {
        background: $palette-secondary-main
      }
    }
    &.accent {
      &:before {
        background: $palette-accent-main
      }
    }
    &.light-blue-bg {
      &:before {
        background: material-color('light-blue', '500')
      }
    }
  }
}