#ai-feature {
  .use-text-grd {
    &-triple-custom {
      .theme--light & {
        @extend .use-text-grd-triple-light;
      }
      .theme--dark & {
        @extend .use-text-grd-triple-main;
      }
    }
  }
  .root {
    position: relative;
    .btn {
      width: 160px;
    }
  }

  .item {
    position: relative;
    min-height: 320px;
    margin-bottom: $spacing10;
    .desc {
      position: relative;
      z-index: 60
    }
    @include breakpoints-down(md) {
      margin-bottom: $spacing10
    }
    @include breakpoints-down(sm) {
      text-align: center
    }
    h6 {
      margin-bottom: $spacing4
    }
    &.last {
      margin-bottom: $spacing10;
      @include breakpoints-down(sm) {
        margin-bottom: 0
      }
    }
  }

  .illustration {
    position: relative;
    figure {
      width: 100%;
      text-align: center;
      position: relative;
      @include breakpoints-down(sm) {
        margin: 0 auto;
        img {
          width: 100%
        }
      }
      img {
        margin: 0 auto;
        max-width: 400px;
        max-height: 500px;
      }
    }
  }
}