$string1: '../images/decoration/string1.svg';
$string2: '../images/decoration/string2.svg';

.line-deco-artwork {
  .line {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 500px;
    bottom: 0;
    overflow: hidden;
  }

  .string-deco {
    width: 100%;
    position: absolute;
    span {
      position: absolute;
      width: 100%;
      height: 100%
    }
  }

  .string1 {
    height: 635px;
    mask-image: url($string1);
    -webkit-mask-image: url($string1);
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-size: 100%;
    -webkit-mask-size: 100%;
    top: 300px;
    left: 0px;
    span {
      @include use-theme(background, $gradient-triple-light, $gradient-triple-dark);
    }
  }

  .string2 {
    z-index: 1;
    height: 700px;
    mask-image: url($string2);
    -webkit-mask-image: url($string2);
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-size: 100%;
    -webkit-mask-size: 100%;
    top: 400px;
    left: 0;
    span {
      @include use-theme(background, $gradient-double-light, $gradient-double-dark);
    }
  }

  .string3 {
    z-index: 1;
    height: 700px;
    mask-image: url($string2);
    -webkit-mask-image: url($string2);
    mask-repeat: no-repeat;
    -webkit-mask-repeat: no-repeat;
    mask-size: 100%;
    -webkit-mask-size: 100%;
    top: 240px;
    left: 0px;
    span {
      @include use-theme(background, $gradient-double-light, $gradient-double-dark);
    }
  }
}