@mixin price-label {
  color: $palette-common-black;
  font-weight: $font-bold;
  text-transform: uppercase;
  border-radius: 36px;
  padding: spacing(0, 4);
  font-size: 22px;
  display: inline-block;
  margin: spacing(1, 0)
}

.pricing-basic-card {
  .price {
    &-card {
      text-align: center;
      border-radius: 36px;
      overflow: hidden;
      @include breakpoints-down(sm) {
        max-width: 320px;
        margin: 0 auto;
      }
      &.card {
        background: none;
        box-shadow: none;
      }
      h2 {
        font-weight: $font-bold;
        span {
          vertical-align: super;
          padding: spacing(0, 0.5);
          font-size: 22px;
        }
        em {
          font-size: 16px;
          text-transform: lowercase;
          font-style: normal;
          font-weight: $font-regular;
        }
      }
      h6 {
        line-height: 28px;
      }
      p {
        margin-bottom: $spacing2
      }
      ul {
        li {
          @include palette-text-primary;
          list-style: none;
          padding: spacing(1, 0);
          font-size: 16px;
          &:last-child {
            border: none;
          }
        }
      }
      &.general {
        h5 {
          background: $gradient-triple-light;
          @include price-label;
        }
      }
      &.value {
        z-index: 10;
        padding: $spacing2;
        box-shadow: none;
        @include palette-background-paper;
        @include shadow20;
        margin-top: $spacing4;
        @include breakpoints-up(sm) {
          margin-top: -30px;
          margin-bottom: -15px;
        }
        .price-header {
          border-radius: $rounded-big;
          color: $palette-common-black;
          background: $gradient-triple-light;
        }
        ul {
          margin-top: $spacing2;
        }
        h2 {
          color: $palette-common-black;
        }
        h5 {
          @include price-label;
          padding: 0 !important;
        }
        h6 {
          color: $palette-common-black;
        }
        &:hover {
          @include shade;
        }
        .btn-area {
          border-image-source: $gradient-triple-main;
          border-image-slice: 1;
          .btn:not(.btn-outlined) {
            background: $palette-primary-main
          }
        }
      }
      &.basic {
        h2 {
          color: $palette-common-white;
        }
        h5 {
          background: $palette-common-white;
          @include price-label;
        }
        h6 {
          color: $palette-common-white;
        }
      }
    }
    &-header {
      display: block;
      padding: spacing(1, 2);
      position: relative;
      .icon {
        position: absolute;
        top: $spacing1;
        color: $palette-common-white;
        @include right($spacing1);
      }
    }
  }

  .title-price {
    font-size: 52px
  }

  .btn-area {
    text-align: center;
    @include palette-text-secondary;
    position: relative;
    padding: spacing(1.5, 5);
    margin-top: $spacing3;
    margin: spacing(0, 2);
    @include breakpoints-up(sm) {
      padding: spacing(3, 2);
      border-top: 1px solid;
      @include use-theme(border-color, rgba(0, 0, 0, 0.12), rgba(255, 255, 255, 0.3));
    }
    p {
      position: relative;
      font-size: 14px;
    }
    .btn:not(.btn-outlined) {
      background: $palette-common-black
    }
  }

  .card-pricing {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    line-height: 52px;
  }
}
