.comments-style {
  .form-comment {
    @include palette-background-paper;
    padding: $spacing1;
    border-radius: $rounded-big;
    @include breakpoints-up(sm) {
      padding: spacing(2, 3);
    }
  }

  .write {
    position: relative;
    display: flex;
    align-items: flex-start;
    .input-field {
      min-height: 0;
      margin-bottom: 0;
      width: 100%;
      border-radius: $rounded-big;
      @include breakpoints-down(sm) {
        padding-bottom: $spacing5;
      }
      @include breakpoints-up(sm) {
        @include padding-right(160px);
      }
      textarea {
        max-height: none;
        resize: none;
        height: 140px;
        width: 100%;
      }
    }
    .send-btn {
      position: absolute;
      bottom: 6px;
      @include right($spacing1);
    }
  }

  .avatar {
    @include margin-right($spacing1);
    margin-top: $spacing1;
  }

  .comment-list {
    margin-top: $spacing2;
    .divider {
      @include palette-divider;
      background: none;
    }
  }

  .date {
    height: auto;
    padding: 0;
    font-size: 12px;
    margin-left: -16px
  }

  .content {
    margin-top: $spacing1;
    margin-bottom: $spacing2;
    line-height: 1.5;
  }

  .help-item {
    text-transform: uppercase;
    font-size: 13px;
  }

}