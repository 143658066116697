.playlist-card {
  overflow: visible;
  border-radius: $rounded-big;
  margin-bottom: $spacing2;
  position: relative;
  background: #424242;
  color: $palette-common-white;
  @include shadow8;
  display: block;
  justify-content: center;
  height: 480px;
  z-index: 2;
  &:not(.deco) {
    overflow: hidden;
    .waves-ripple {
      z-index: 1;
    }
  }
  img {
    z-index: 1;
  }
  @include breakpoints-down(md) {
    height: 320px;
  }
  &.primary-dark {
    background: $gradient-primary-dark
  }
  &.primary-light {
    background: $gradient-primary-light
  }
  &.secondary-dark {
    background: $gradient-secondary-dark
  }
  &.secondary-light {
    background: $gradient-secondary-light
  }
  &.accent {
    background: $gradient-accent
  }
  &.double-light {
    background: $gradient-double-light
  }
  &.double-main {
    background: $gradient-double-main
  }
  &.double-dark {
    background: $gradient-double-dark
  }
  &.triple-light {
    background: $gradient-triple-light
  }
  &.triple-main {
    background: $gradient-triple-main
  }
  &.triple-dark {
    background: $gradient-triple-dark
  }
  &.deco {
    @include breakpoints-down(lg) {
      &:before, &:after {
        display: none
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 65px;
      @include left(50px);
      width: 85%;
      height: 100%;
      border-radius: $rounded-big;
      background: $gradient-triple-main;
      transform: rotate(178deg)
    }
    &:after {
      content: "";
      position: absolute;
      top: 90px;
      @include left(40px);
      width: 90%;
      height: 90%;
      border-radius: $rounded-big;
      background: $gradient-triple-light;
      transform: rotate(3deg)
    }
  }
  .figure {
    border-radius: $rounded-big;
    overflow: hidden;
    margin: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      display: block;
      position: relative;
    }
  }
  .property {
    transition: all 0.3s ease-out;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    min-height: 170px;
    border-radius: $rounded-big;
    padding: $spacing2;
    bottom: 0;
    @include text-align(left);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include left(0);
    left: 0;
    color: $palette-common-white;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute
    }
    @include breakpoints-down(lg) {
      padding: $spacing1;
      text-align: center;
      bottom: 0;
      .desc {
        display: none
      }
    }
    &.primary {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 30%, $palette-primary-main 80%);
    }
    &.secondary {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 30%, $palette-secondary-main 80%);
    }
    &.accent {
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 30%, $palette-accent-light 80%);
    }
  }

  .desc {
    font-weight: $font-regular;
    font-size: 16px;
    position: relative;
  }

  .user {
    display: flex;
    align-items: center;
    position: relative
  }

  .head {
    position: relative;
    display: flex;
    align-items: center;
    @include breakpoints-up(md) {
      margin-bottom: $spacing3;
    }
  }

  .title-card {
    text-transform: capitalize;
    font-weight: $font-bold;
    font-size: 36px;
    display: block;
    line-height: normal;
    @include breakpoints-down(md) {
      font-size: 24px;
    }
    .verified {
      @include breakpoints-up(md) {
        font-size: 32px;
      }
    }
  }

  .text {
    display: block
  }

  .name {
    display: block;
    font-weight: $font-medium;
  }

  .avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    @include palette-background-paper;
    text-align: center;
    @include margin-right($spacing1);
    @include breakpoints-down(lg) {
      display: none
    }
    img {
      display: block;
      width: 100%
    }
  }

  .price {
    position: absolute;
    top: 1px;
    z-index: 1;
    font-weight: $font-medium
  }

  .verified {
    color: $palette-secondary-main;
    font-size: 16px;
    @include margin-left($spacing1)
  }

  .gallery {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    margin-top: $spacing3;
    height: 200px;
    justify-content: space-between;
    li {
      width: 50%;
      height: 100%;
      overflow: hidden;
      margin-left: -50%;
      position: relative;
      border-radius: $rounded-big;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        display: block
      }
      &:first-of-type {
        @include margin-left(0)
      }
      span {
        position: absolute;
        top: 0;
        z-index: 1;
        @include left(0);
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        color: $palette-common-white;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: $font-medium;
        svg {
          width: 36px;
          height: 36px;
          @include margin-right(4px)
        }
      }
    }
  }
  .logo {
    width: 80px;
    height: 80px;
    display: block;
    border-radius: $rounded-medium;
    overflow: hidden;
    @include palette-background-paper;
    text-align: center;
    @include margin-right($spacing2);
    @include breakpoints-down(lg) {
      display: none
    }
    img {
      display: block;
      width: 100%
    }
  }
}
