#avatar-statistic {
  .icon-card {
    position: relative;
    text-align: center;
    padding: 2px;
    overflow: hidden;
    width: 180px;
    height: 180px;
    border-radius: $rounded-big;
    background: $gradient-double-main;
    box-shadow: none;
    @include breakpoints-down(sm) {
      width: 90px;
      height: 90px;
    }
  }

  .icon {
    font-size: 160px;
    height: 100%;
    text-align: center;
    border-radius: $rounded-big;
    @include use-theme(background, rgba($light-background-paper, 0.75), rgba($dark-background-paper, 0.75));
    @include breakpoints-down(sm) {
      font-size: 80px;
    }
    > div {
      position: relative;
      @include left(-20px);
      top: -40px;
      @include breakpoints-down(sm) {
        @include left(-8px);  
        top: -20px;
      }
    }
  }
}