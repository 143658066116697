#cv-navigation {
  .logo {
    display: block;
    margin: spacing(1.5, 0);
    position: relative;
    text-align: center;
    img {
      max-width: 64px;
      width: 100%;
      margin: 0 auto;
    }
  }

  .menu {
    margin: 0 auto;
    padding: spacing(1, 0);
    border-radius: 40px;
    width: 80px;
    background: $palette-common-black;
    ul {
      border: none;
      overflow: visible;
      padding: 0;
      margin: 0;
      li {
        float: none;
      }
    }
  }

  .icon {
    display: block;
    min-width: 0;
    margin: 0 auto !important;
    width: 100%;
    @include left(-2px);
    position: relative;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    line-height: 48px;
    span {
      font-size: 36px;
      z-index: 2;
      position: relative;
      color: $palette-common-white
    }
  }

  .text {
    @include palette-text-primary;
    transition: all 0.3s cubic-bezier(0, 1.73, 1, 1.02);
    position: relative;
    visibility: hidden;
    position: absolute;
    left: 40px;
    top: 12px;
    @include use-theme(background, $palette-secondary-light, $palette-secondary-dark);
    text-transform: capitalize;
    border-radius: $rounded-medium;
    padding: spacing(1, 2);
    z-index: 2;
    white-space: nowrap;
    opacity: 0;
    font-size: 18px;
    font-weight: $font-medium;
  }
  .deco {
    &:after {
      opacity: 0;
      content: '';
      background: $palette-common-black;
      position: absolute;
      bottom: -32px;
      @include left(0);
      width: 100%;
      height: 32px;
      transition: border-radius 0.5s ease;
      border-top-right-radius: 0
    }
    &:before {
      opacity: 0;
      content: '';
      @include use-theme(background, $light-background-paper, rgba(var(--v-theme-primarydark), 0.5));
      position: absolute;
      bottom: -32px;
      @include left(0);
      width: 100%;
      height: 32px;
      border-radius: 0;
    }
  }
  .link {
    text-align: center;
    background: transparent;
    transition: border-radius 0.5s ease;
    border-radius: 0;
    border-top-left-radius: $rounded-small;
    border-bottom-left-radius: $rounded-small;
    padding: $spacing1;
    position: relative;
    @include margin-left(4px);
    margin-bottom: 0 !important;
    .darken {
      transition: border-radius 0.5s ease;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-top-left-radius: $rounded-small;
      border-bottom-left-radius: $rounded-small;
    }
    a {
      padding: 0
    }
    &:last-child {
      .deco {
        display: none
      }
    }
    &:first-child {
      &:after, &:before {
        display: none
      }
    }
    &:after {
      opacity: 0;
      content: '';
      border: none;
      background: $palette-common-black;
      position: absolute;
      top: -32px;
      @include left(0);
      width: 100%;
      height: 32px;
      transition: border-radius 0.5s ease;
      border-bottom-right-radius: 0;
      min-height: 0;
    }
    &:before {
      opacity: 0;
      content: '';
      @include use-theme(background, $light-background-paper, rgba(var(--v-theme-primarydark), 0.5));
      position: absolute;
      top: -32px;
      @include left(0);
      width: 100%;
      height: 32px;
      min-height: 0;
      border-radius: 0
    }
    &:hover {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      @include use-theme(background, $light-background-paper, $palette-common-black);
      &:before {
        opacity: 1;
      }
      &:after {
        opacity: 1;
        border-bottom-right-radius: 100px
      }
      .darken {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        @include use-theme(background, $light-background-paper, rgba(var(--v-theme-primarydark), 0.45));
      }
      .deco {
        &:before {
          opacity: 1
        }
        &:after {
          opacity: 1;
          border-top-right-radius: 100px
        }
      }
      .icon {
        z-index: 3;
        background: linear-gradient(320deg, $palette-primary-light 40%, $palette-secondary-light 120%);
        span {
          color: $palette-common-black;
        }
      }
      .text {
        background: linear-gradient(320deg, $palette-primary-light 40%, $palette-secondary-light 120%);
        color: $palette-common-black;
        border-radius: $rounded-big;
        opacity: 1;
        visibility: visible;
        @include left(70px);
      }
    }
  }
}