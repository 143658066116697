#cv-statistic {
  .img {
    position: relative;
    text-align: center;
    width: 180px;
    height: 180px;
    border-radius: 40px;
    overflow: hidden;
    @include palette-background-paper;
    @include breakpoints-down(md) {
      width: 90px;
      height: 90px;
      border-radius: 20px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}