.mobile-app-artwork {
  .root {
    perspective: 1000px;
    display: block;
    position: relative;
  }

  .phone {
    width: 250px;
    height: 470px;
    position: relative;
    transform: rotateY(-42deg) rotateX(27deg) rotateZ(-2deg);
    border-radius: $rounded-big;
    background: linear-gradient(0deg, $palette-secondary-main -20%, $palette-secondary-light -10%, $palette-primary-dark 50%, $palette-primary-main 55%, $palette-accent-light 80%, $palette-secondary-main 120%);
  }

  .screen {
    width: 250px;
    height: 480px;
    border-radius: $rounded-big;
    overflow: hidden;
    position: relative;
    left: -8px;
    top: -8px;
    img {
      width: 100%
    }
  }

  .reflect {
    position: absolute;
    bottom: -109%;
    left: -64%;
    @include use-rtl-ltr(display, none, block);
    @include use-theme(opacity, 0.2, 0.8);
    transform: rotateY(-42deg) rotateX(27deg) rotateZ(-2deg) scale(1.1, -1.1);
    &:before {
      content: "";
      position: absolute;
      width: 120%;
      height: 110%;
      bottom: -20px;
      left: -20px;
      z-index: 1;
      @include use-theme(background, linear-gradient(to bottom, $light-background-paper 85%, rgba(0, 0, 0, 0) 110%), linear-gradient(to bottom, $palette-primary-dark 85%, rgba(0, 0, 0, 0) 110%));
    }
    &:after {
      content: "";
      position: absolute;
      width: 120%;
      height: 110%;
      bottom: -20px;
      left: -20px;
      z-index: 1;
      opacity: 0.5;
      @include use-theme(background, linear-gradient(to bottom, $light-background-paper 85%, rgba(0, 0, 0, 0) 130%), linear-gradient(to bottom, $palette-common-black 85%, rgba(0, 0, 0, 0) 130%));
    }
  }

  .widget {
    transform: rotateY(-42deg) rotateX(27deg) rotateZ(-2deg);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    span {
      z-index: 2;
      position: absolute;
      border-radius: $rounded-medium;
      overflow: hidden;
      boxShadow: 0 10px 20px 5px rgba(0, 0, 0, 0.25);
      img {
        width: 100%
      }
    }
  }

  .top {
    width: 220px;
    height: 85px;
    top: -35px;
    left: -190px;
  }

  .left {
    width: 160px;
    height: 80px;
    bottom: 20px;
    left: -40px;
    @include breakpoints-up(sm) {
      left: -80px;
    }
  }

  .right {
    width: 195px;
    height: 100px;
    top: 250px;
    right: -90px;
  }
}