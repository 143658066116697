.post-card {
  border-radius: $rounded-medium;
  overflow: hidden;
  position: relative;
  background: #424242;
  @include shadow8;
  display: flex;
  justify-content: center;
  min-height: 250px;
  text-decoration: none;
  color: $palette-common-white;
  .figure {
    margin: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      display: block;
      position: relative
    }
  }
  .property {
    transition: all 0.3s ease-out;
    position: absolute;
    width: 100%;
    height: 150%;
    min-height: 170px;
    padding: $spacing2;
    bottom: 0;
    @include text-align(left);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    left: 0;
    color: $palette-common-white;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      opacity: 0.9;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, $palette-secondary-dark 85%, $palette-primary-main 100%);
    }
    @include breakpoints-down(lg) {
      padding: $spacing1;
      bottom: 0;
    }
  }
  .title-card {
    text-transform: capitalize;
    font-weight: $font-bold;
    line-height: 36px;
    position: relative;
    display: block;
    margin-bottom: $spacing1;
    &.small {
      font-size: 18px;
    }
    &.big {
      font-size: 24px;
    }
  }
  .desc {
    position: relative;
    display: block;
    margin-top: $spacing2;
    span {
      display: block
    }
  }
  .source {
    margin-bottom: $spacing1;
    opacity: 0.75;
    font-size: 12px;
    font-weight: $font-regular
  }
}

