#nft2-auction {
  .root {
    position: relative;
    overflow: hidden;
    z-index: 10;
    padding-bottom: $spacing3;
    @include breakpoints-up(sm) {
      padding-bottom: $spacing10;
    }
    .slick-dots {
      bottom: -32px;
      @include pagination-slide;
    }
  }
  .slide {
    border-radius: 40px;
    overflow: hidden;
    position: relative;
    .inner-slide {
      height: 430px;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column
    }
    a {
      z-index: 100;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0
    }
    figure {
      position: absolute;
      background: $palette-common-black;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      img {
        margin: 0;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        object-fit: cover
      }
    }
  }

  .title-slide {
    position: relative;
    font-size: 48px;
    color: $palette-common-white;
    font-weight: $font-medium;
    @include breakpoints-down(sm) {
      font-size: 24px;
    }
  }

  .countdown-wrap {
    padding: $spacing2;
    position: relative;
    z-index: 2
  }

  .property {
    position: relative;
    color: $palette-common-white;
    p {
      margin-bottom: $spacing2;
    }
    strong {
      font-weight: $font-medium;
    }
  }

  .price {
    color: $palette-secondary-main
  }

  .nav {
    position: absolute;
    top: 47%;
    border-radius: $rounded-small;
    width: 49px;
    height: 100px;
    padding: 0px;
    min-width: 0px;
    z-index: 10;
    background: $palette-primary-main;
    @include shadow3;
    @include use-rtl-ltr(transform, scale(-1.6), scale(1.6));
    &:hover {
      background: $palette-primary-dark;
    }
    i {
      font-size: 24px;
      @include palette-text-primary;
    }
    @include breakpoints-down(md) {
      display: none
    }
    &[disabled] {
      opacity: 0.3;
    }
  }

  .prev {
    @include left(-2px)
  }
  .next {
    @include right(-2px)
  }

}