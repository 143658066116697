#wallet-feature {
  .use-text-grd {
    &-triple-custom {
      .theme--light & {
        @extend .use-text-grd-triple-light;
      }
      .theme--dark & {
        @extend .use-text-grd-triple-main;
      }
    }
  }

  .root {
    position: relative;
  }

  .parallax-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden
  }

  .item {
    position: relative;
    min-height: 320px;
    .desc {
      position: relative;
      z-index: 60;
      @include breakpoints-down(md) {
        text-align: center;
      }
    }
    @include breakpoints-between(sm, md) {
      padding-top: $spacing5
    }
    @include breakpoints-up(md) {
      padding-bottom: $spacing10
    }
    h6 {
      margin-bottom: $spacing4
    }
    &.last {
      margin-bottom: $spacing10;
      @include breakpoints-down(sm) {
        margin-bottom: 0;
        padding-bottom: $spacing13;
      }
      .illustration {
        @include breakpoints-down(sm) {
          top: 0;
        }
      }
    }
  }

  .illustration {
    position: relative;
    @include breakpoints-down(sm) {
      top: -40px;
      position: relative;
    }
  }

  .mobile-art {
    @include breakpoints-up(md) {
      top: -200px;
    }
    @include breakpoints-up(md) {
      position: absolute;
      @include right(100px);
    }
    @include breakpoints-down(md) {
      transform: scale(0.8);
      width: 250px;
      margin: 0 auto;
      margin-bottom: -20px;
    }
  }

  .share-art {
    position: relative;
    @include breakpoints-between(sm, md) {
      width: 600px;
      top: -40px;
      @include left(-20px)
    }
  }

  .parallax-logo {
    height: 600px;
    position: relative;
    background: url(../images/wallet/crypto_logo.png) no-repeat transparent center center;
    background-attachment: fixed;
    background-size: cover;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      z-index: 1;
      @include use-theme(background, linear-gradient(to bottom, $light-background-paper 10%, rgba(0, 0, 0, 0) 50%, $light-background-paper 100%), linear-gradient(to bottom, $palette-primary-dark 10%, rgba(0, 0, 0, 0) 50%, $palette-primary-dark 100%));
    }
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      z-index: 1;
      opacity: 0.5;
      @include use-theme(background, linear-gradient(to bottom, $light-background-paper 10%, rgba(0, 0, 0, 0) 50%, $light-background-paper 100%), linear-gradient(to bottom, $palette-common-black 10%, rgba(0, 0, 0, 0) 50%, $palette-common-black 100%));
    }
  }
}
