#blur-gradient-artwork {
  .primary-dark { background: $gradient-primary-dark }
  .primary-light { background: $gradient-primary-light }
  .secondary-dark { background: $gradient-secondary-dark }
  .secondary-light { background: $gradient-secondary-light }
  .accent { background: $gradient-accent }
  .double-light { background: $gradient-double-light }
  .double-main { background: $gradient-double-main }
  .double-dark { background: $gradient-double-dark }
  .triple-light { background: $gradient-triple-light }
  .triple-main { background: $gradient-triple-main }
  .triple-dark { background: $gradient-triple-dark }
  .fogs {
    position: absolute;
    width: 100%;
    height: 200px;
    top: 0px;
    left: 0px;
    filter: blur(100px);
    @include use-theme(opacity, 0.35, 0.75);
    padding: spacing(15, 0, 5);
    .fog {
      position: absolute;
      width: 60%;
      height: 100%;
      > div > div {
        position: absolute;
      }
    }
    .start {
      bottom: 0px;
      overflow: hidden;
      left: 0;
      .ctx {
        transform: translateX(-80px);
      }
    }
    .end {
      bottom: 0px;
      overflow: hidden;
      right: 0;
      .ctx {
        transform: translateX(80px)
      }
    }
  }
}